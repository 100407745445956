<template>
    <!--
    Компонент вывода даты с возможностью редактирования
    -->

    <div class="dom-datepicker-label" :class="{v2: v2}">

        <dom-label :prefix-text="prefixText"
                   :prefix-icon="prefixIcon"
                   :suffix-icon="suffixIcon"
                   @siffix-icon-click="onStartEdit">
            {{valueInternal}}
        </dom-label>

        <el-dialog
            class="edit-dialog"
            :visible.sync="showDialog"
            append-to-body
            center
            :close-on-click-modal="false"
            :show-close="false">

            <template v-slot:title>
                <dom-title-closable :value="editDialogTitle" @close="onEditDialogCancel"/>
            </template>

            <el-date-picker
                class="dom-datepicker"
                v-model="newValue"
                type="date"
                :firstDayOfWeek="1"
                format="dd.MM.yyyy"
                :editable="false">
            </el-date-picker>

            <template v-slot:footer>
                <dom-button-accept :label="editDialogSubmit" :disabled="!formIsReady" @click="onEditDialogSubmit"/>
            </template>

        </el-dialog>

    </div>

</template>

<script>

import moment from "moment";
import DomInput from "@/views/elements/DomInput";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomLabel from "@/views/elements/DomLabel";
import ValueInternal from "@/views/mixins/ValueInternal";
import DomTitleClosable from "@/views/elements/DomTitleClosable";

export default {
    components: {DomTitleClosable, DomLabel, DomButtonAccept, DomInput},
    props: {
        prefixText: {
            type: String,
        },
        v2: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },

        prefixIcon: {
            type: String,
        },
        editDialogTitle: {
            type: String,
            default: "Выберите новое значение",
        },
        editDialogPlaceholder: {
            type: String,
            default: "Новое значение",
        },
        editDialogSubmit: {
            type: String,
            default: "Сохранить",
        },

    },

    mixins: [ValueInternal],


    data: () => ({
        showDialog: false,
        newValue: null,
    }),

    computed: {
        suffixIcon() {
            return this.editable ? "fas el-icon-fa-pen-square" : null
        },

        formIsReady() {
            return !!this.newValue
        },

    },

    methods: {
        onStartEdit() {
            this.newValue = this.valueInternal
            this.showDialog = true;
        },

        onEditDialogCancel() {
            this.showDialog = false;
        },

        onEditDialogSubmit() {
            this.showDialog = false;
            this.$emit('input', moment(this.newValue).toISOString())
        },
    }

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-datepicker-label {
    width: 100%;
    height: 100%;
    line-height: 50px;
    box-sizing: border-box;

    ::v-deep .suffix-icon {
        cursor: pointer;
    }

    .el-date-picker {
        width: 100%;
    }

    &.v2 .dom-label {
        background-color: $dom-light-gray;
        border-radius: 24px;
        height: 100%;
        padding: 10px 10px;
        margin: 5px 0;

        ::v-deep .suffix-icon {
            color: $dom-gray;
        }
    }

    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;

        ::v-deep .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }

}

.el-radio-group {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: $dom-light-gray;
    padding: 8px 16px;
    box-sizing: border-box;

    .el-radio {
        background-color: $dom-white;
        width: 100%;
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        margin: 8px 0;



        ::v-deep .el-radio__label {
            word-break: normal;
            white-space: normal;
            display: inline-block;
            font-size: 16px;
        }
    }
}



</style>
