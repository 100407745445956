<template>
    <!--
    Компонент нередактируемого текста
    -->

    <div class="dom-label" :class="{'has-prefix-icon': !!prefixIcon}">

        <span v-if="prefixText" class="prefix-text">

            {{prefixText}}
        </span>

        <span>&nbsp;</span>

        <span class="value-text"><slot/></span>

        <i v-if="prefixIcon" class="prefix-icon" :class="prefixIcon" @click="onPrefixClick"/>
        <div class="prefix-icon-slot">
            <slot name="prefix-icon"/>
        </div>

        <i v-if="suffixIcon" class="suffix-icon" :class="suffixIcon" @click="onSuffixClick"/>

    </div>

</template>

<script>

import DomInput from "@/views/elements/DomInput";
import DomButtonAccept from "@/views/elements/DomButtonAccept";

export default {
    components: {DomButtonAccept, DomInput},
    props: {
        prefixText: {
            type: String,
        },
        prefixIcon: {
        },
        suffixIcon: {
            type: String,
        },
    },

    data: () => ({
    }),

    methods: {
        onPrefixClick() {
            this.$emit('prefix-icon-click')
        },

        onSuffixClick() {
            this.$emit('siffix-icon-click')
        },

    }

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-label {
    background-color: $dom-white;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 50px;
    line-height: initial;

    display: flex;
    align-content: center;
    flex-wrap: wrap;

    position: relative;

    padding-left: 10px;
    &.has-prefix-icon {
        padding-left: 45px;
    }

    .prefix-text {
        color: $dom-dark-gray;
    }

    .value-text {
        font-weight: 600;
    }

    .prefix-icon {
        position: absolute;
        font-size: 18px;
        left: 6px;
        top: 50%;
        margin-top: -9px;
        color: $dom-text-blue;
    }

    .prefix-icon-slot {
        position: absolute;
        font-size: 18px;
        left: 0;
        top: 0;
        margin-top: 0;
    }

    .suffix-icon {
        position: absolute;
        font-size: 18px;
        right: 16px;
        top: 10px;
        color: $dom-yellow;
    }


}


</style>
