<template>
    <!--
    Компонент вывода длинного текста с возможност. редактирования
    -->

    <div class="dom-longtext-label" :class="{v2: v2}">

        <dom-label :prefix-text="prefixText"
                   :prefix-icon="prefixIcon"
                   :suffix-icon="suffixIcon"
                   @siffix-icon-click="onStartEdit">

            <template v-slot:prefix-icon>
                <slot name="prefix-icon"/>
            </template>{{valueInternal}}
        </dom-label>

        <el-dialog
            :width="dialogWidth"
            :class="{dialog_full_display: !$isMobile()}"
            class="edit-dialog"
            :visible.sync="showDialog"
            append-to-body
            center
            :close-on-click-modal="false"
            :show-close="false">

            <template v-slot:title>
                <dom-title-closable :value="editDialogTitle" @close="onEditDialogCancel"/>
            </template>

            <el-row>
                <dom-textarea v-model="newValue" :placeholder="editDialogPlaceholder" maxlength="512" clearable/>
            </el-row>

            <el-row>
                <el-col :span="4">
                    <dom-button-accept :label="editDialogSubmit" :disabled="!formIsReady" @click="onEditDialogSubmit"/>
                </el-col>
            </el-row>
        </el-dialog>

    </div>

</template>

<script>

import DomInput from "@/views/elements/DomInput";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomLabel from "@/views/elements/DomLabel";
import ValueInternal from "@/views/mixins/ValueInternal";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import DomTextarea from "@/views/elements/DomTextarea";
import VueScreenSize from "vue-screen-size";

export default {
    components: {DomTextarea, DomTitleClosable, DomLabel, DomButtonAccept, DomInput},
    props: {
        prefixText: {
            type: String,
        },
        v2: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        prefixIcon: {
            default: null,
        },
        editDialogTitle: {
            type: String,
            default: "Введите новое значение",
        },
        editDialogPlaceholder: {
            type: String,
            default: "Новое значение",
        },
        editDialogSubmit: {
            type: String,
            default: "Сохранить",
        },
    },

    mixins: [ValueInternal, VueScreenSize.VueScreenSizeMixin],


    data: () => ({
        showDialog: false,
        newValue: null,
    }),

    computed: {
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },
        suffixIcon() {
            return this.editable ? "fas el-icon-fa-pen-square" : null
        },

        formIsReady() {
            return this.newValue
        },
    },

    methods: {
        onStartEdit() {
            this.newValue = this.valueInternal
            this.showDialog = true;
        },

        onEditDialogCancel() {
            this.showDialog = false;
        },

        onEditDialogSubmit() {
            this.showDialog = false;
            this.$emit('input', this.newValue.trim())
        },
    }

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dialog_full_display {
    max-height: 70vh;
    margin-top: 35px;
    width: 100%;
}

.edit-dialog {

    ::v-deep.el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
        width: 100%;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__footer {
        background: $dom-light-gray;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }
}

.dom-label {
    display: block;


    & ::v-deep .value-text {
        display: block;
        white-space: pre-line;
    }

}

.dom-longtext-label {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    ::v-deep .suffix-icon {
        cursor: pointer;
    }

    &.v2 .dom-label {
        background-color: $dom-light-gray;
        border-radius: 4px;
        height: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;

        ::v-deep .suffix-icon {
            color: $dom-gray;
        }
    }

}


</style>
