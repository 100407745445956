<template>
    <!--
    Компонент чекбокс
    -->

    <el-checkbox v-model="valueInternal" class="dom-checkbox"
                 @click="$emit('click', $event)"
                 :disabled="disabled"
                 :class="{disabled : disabled}">
        <span style="font-size: 18px; padding: 8px">
            {{ label }}
        </span>
    </el-checkbox>

</template>

<script>
import ValueInternal from "@/views/mixins/ValueInternal";

export default {
    props: {
        label: {},
        disabled: {}
    },

    mixins: [ValueInternal]
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-checkbox {
    width: 100%;
    background: white;
    padding: 15px 22px;
    box-sizing: border-box;

    ::v-deep .el-checkbox__label {
        padding-left: 10px;
    }

    ::v-deep .el-checkbox__inner {
        border-color: $dom-dark-gray;
    }

    &.is-checked ::v-deep .el-checkbox__inner {
        border-color: $dom-blue;
    }
}
.disabled {
    opacity: .3;
}



</style>
