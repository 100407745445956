<template>
    <!--
    Компонент поля с выбором значения из списка через диалог
    -->

    <div class="dom-selectable-label" :class="{v2: v2}">

        <dom-label :prefix-text="prefixText"
                   :prefix-icon="prefixIcon"
                   :suffix-icon="suffixIcon"
                   @siffix-icon-click="onStartEdit">
            {{ valueToDisplay }}
        </dom-label>

        <el-dialog
            :class="{dialog_full_display: !$isMobile(), dialog_mobile: $isMobile()}"
            class="edit-dialog"
            :visible.sync="showDialog"
            append-to-body
            :close-on-click-modal="false"
            :show-close="false">

            <template v-slot:title>
                <dom-title-closable :value="editDialogTitle" @close="onEditDialogCancel"/>
            </template>

            <el-radio-group v-model="newValue" class="items">
                <template v-for="item in items" class="item">
                    <el-radio :label="item[valueKey]">{{ item[textKey] }}</el-radio>
                </template>
            </el-radio-group>

            <template v-slot:footer>
                <dom-button-accept :label="editDialogSubmit" :disabled="!formIsReady" @click="onEditDialogSubmit"/>
            </template>

        </el-dialog>

    </div>

</template>

<script>

import DomInput from "@/views/elements/DomInput";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomLabel from "@/views/elements/DomLabel";
import ValueInternal from "@/views/mixins/ValueInternal";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import VueScreenSize from "vue-screen-size";

export default {
    components: {DomTitleClosable, DomLabel, DomButtonAccept, DomInput},
    props: {
        prefixText: {
            type: String,
        },
        v2: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },

        items: {
            type: Array,
            required: true,
        },
        valueKey: {
            type: String,
            default: 'id'
        },
        textKey: {
            type: String,
            default: 'text'
        },


        prefixIcon: {
            type: String,
        },
        editDialogTitle: {
            type: String,
            default: "Выберите новое значение",
        },
        editDialogPlaceholder: {
            type: String,
            default: "Новое значение",
        },
        editDialogSubmit: {
            type: String,
            default: "Сохранить",
        },
        useKey: {
            type: Boolean,
            default: false,
        },

    },

    mixins: [ValueInternal, VueScreenSize.VueScreenSizeMixin],


    data: () => ({
        showDialog: false,
        newValue: null,
    }),

    computed: {
        suffixIcon() {
            return this.editable ? "fas el-icon-fa-pen-square" : null
        },

        formIsReady() {
            return !!this.newValue
        },

        valueToDisplay() {
            if (this.useKey) {
                const found = _.find(this.items, d => d[this.valueKey] === this.value);
                return found ? found[this.textKey] : ""
            } else {
                return this.value ? this.value[this.textKey] : ""
            }
        },
    },

    methods: {
        onStartEdit() {
            this.newValue = this.valueInternal
            this.showDialog = true;
        },

        onEditDialogCancel() {
            this.showDialog = false;
        },

        onEditDialogSubmit() {
            let newValue = this.newValue;
            this.$emit('input', newValue)
            this.showDialog = false;
        },
    }

}
</script>

<style lang="scss">


</style>


<style scoped lang="scss">
@import "~@/theme.scss";


.dialog_full_display {
    max-height: 90vh;
    width: 80%;
    margin: 35px auto;
}
.dialog_mobile {
    width: 100%;
}
.edit-dialog {

    ::v-deep.el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
        width: 100%;
    }

    ::v-deep .el-dialog__header {
        position: relative;
        z-index: 10;
        box-shadow: $dom-shadow;
        padding: 20px 20px;
    }

    ::v-deep .el-dialog__footer {
        background: $dom-light-gray;
    }

    ::v-deep .el-dialog__body {
        padding: 0;

        .el-tabs__header {
            padding: 20px 0;
            background-color: white;
        }
    }
}


.dom-selectable-label {
    width: 100%;
    height: 100%;
    line-height: 50px;
    box-sizing: border-box;

    ::v-deep .suffix-icon {
        cursor: pointer;
    }

    &.v2 .dom-label {
        background-color: $dom-light-gray;
        border-radius: 24px;
        height: 100%;
        padding: 10px 10px;
        margin: 5px 0;

        ::v-deep .suffix-icon {
            color: $dom-gray;
        }
    }

}

.el-radio-group {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    background-color: $dom-light-gray;
    padding: 8px 16px;
    box-sizing: border-box;

    .el-radio {
        background-color: $dom-white;
        width: 100%;
        display: flex;
        padding: 10px;
        box-sizing: border-box;
        margin: 8px 0;


        ::v-deep .el-radio__label {
            word-break: normal;
            white-space: normal;
            display: inline-block;
            font-size: 16px;
        }
    }
}


</style>
