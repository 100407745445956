<template>
    <!--
    Диалог регистрации пользователя
    -->
    <el-dialog
        :width="dialogWidth"
        class="paper-dialog"
        :class="{dialog_full_display: !$isMobile()}"
        :visible.sync="isActive"
        append-to-body
        center
        :close-on-click-modal="false"
        :show-close="false"
    >
        <template v-slot:title>
            <dom-title-closable value="Регистрация пользователя" @close="onCancel"/>
        </template>

        <el-row align="middle" class="paper-dropdown dropdown">
            <el-col :span="24">
                <dom-input v-model="email" placeholder="E-mail" icon-left="fas el-icon-fa-at"/>
            </el-col>

            <el-col :span="24" style="margin-top: 15px">
                <dom-datepicker v-if="user.role.roleCd ==='owner_fl_unreg'" v-model="user.birthDate" placeholder="Дата рождения" prefix-icon="fas el-icon-fa-calendar"/>
            </el-col>

            <el-col :span="24" style="margin-top: 15px">
                <dom-input v-model="phoneNumber" placeholder="Номер телефона" icon-left="fas el-icon-fa-phone-square-alt"/>

            </el-col>
        </el-row>

        <el-row>
            <el-col :span="8">
                <dom-button style="margin-top: 10px"
                            @click="onRegisterUser"
                            left-icon="el-icon-circle-check"
                            right-icon="el-icon-caret-right"
                            :disabled="!formIsCompleted"
                            label="Сохранить и завершить"/>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import {mapActions} from "vuex";
import DomDropdown from "@/views/elements/DomDropdown";
import DomCheckbox from "@/views/elements/DomCheckbox";
import DomInput from "@/views/elements/DomInput";
import DomDatepicker from "@/views/elements/DomDatepicker";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import {mapFields} from "vuex-map-fields";
import VueScreenSize from "vue-screen-size";

export default {
    name: "RegisterUserDialog",
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],
    components: {DomTitleClosable, CodeConfirmationDialog, DomDatepicker, DomInput, DomCheckbox, DomDropdown, DomButton, DomTextarea},
    data() {
        return {
            voteConfirmationDialog: false,

            email: null,
            phoneNumber: null,
        }
    },

    computed: {
        ...mapFields("user", {user: 'currentUser'}),

        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        formIsCompleted() {
            return this.user.role.roleCd === 'owner_fl_unreg' ? this.email && this.user.birthDate : this.email
        }
    },

    mounted() {

    },

    methods: {
        ...mapActions("users", {registerUser: "register"}),
        ...mapActions("user", {get_user: "get_user"}),
        onCancel() {
            this.email = null;
            this.phoneNumber = null;
            this.isActive = false;
        },

        onRegisterUser() {
            const roleCd =
                this.user.role.roleCd === 'owner_fl_unreg' ? 'owner_fl' :
                    this.user.role.roleCd === 'owner_org_unreg' ? 'owner_org' :
                        null

            const data = {
                email: this.email,
                birthDate: this.user.birthDate,
                phoneNumber: this.phoneNumber,
                roleCd: roleCd
            }

            const id = this.user.userId;

            this.registerUser({id, data}).then(() => {
                this.$message.success(`Пользователь "${this.user.name}" успешно зарегистрирован.`);
                this.get_user(id)
                this.isActive = false;
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.dialog_full_display {
    max-height: 70vh;
    margin-top: 35px;
    width: 100%;
}

.paper-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }

    .upload_button {
        padding: 12px 0;
        padding-left: 7px;
        padding-right: 27px;
        vertical-align: center;
        text-align: left;
        color: $dom-light-blue;
        border-radius: 5px;
        border: 2px solid $dom-light-blue;
        background-color: $dom-white;

        ::v-deep i {
            font-size: 18px;
        }

        ::v-deep span {
            margin: 0 10px;
            margin-left: 20px;
            line-height: 20px;
            font-size: 14px;
        }

    }

    ::v-deep .el-dialog__body {
        background: $dom-light-gray;
    }

    //радио кнопки
    ::v-deep .el-radio__input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid $dom-light-blue;
    }

    ::v-deep .el-radio__input.is-checked {
        .el-radio__inner {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $dom-green;
        }
    }

    ::v-deep .el-radio__inner {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    ::v-deep .vote-radio span {
        font-size: 16px;
    }

    .vote-radio {
        margin-left: 45px;
        padding: 10px 0;
    }


    .paper-row {
        padding: 7px;
        background: $dom-white;
        min-height: 30px;
        margin-bottom: 12px;
        margin-top: 10px;
        border-radius: 4px;

        ::v-deep i {
            color: $dom-light-blue
        }


        ::v-deep .question__index {
            width: 25px;
            height: 25px;
            line-height: 25px;
            color: $dom-white;
            background: $dom-green;
            font-size: 18px;
            border-radius: 50%;
            margin-left: 10px;
        }

        ::v-deep .question__title {
            background: $dom-light-gray;
            border-radius: 53px;
            font-size: 14px;
            min-height: 30px;

        }

        ::v-deep .question__title_icon {
            color: $dom-light-blue;
            padding-right: 10px;
            font-size: 24px;
        }
    }


}


</style>
